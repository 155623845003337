
.D6_AboveFooter_Container{
    width: 100%;
    background-color: black;
    /* height: 260px;   */
    height: auto;
    display: flex;
    color: white;
    justify-content: center;
    font-weight: 600;
    margin-top: 0px;
    padding-top:20px;
}

.D6_AF_Grid_Cont {
    width: 100%;
}

.D6_AboveFooter_Item {
 /*   padding-top: 20px;  */
    overflow: hidden;
}

#D6_AboveFooter_Item-1 {
    max-width: 300px;
    max-width: 300px;
  /*  height: 380px; */
    overflow: hidden;
    text-align: left;
    height: 280px; 
    margin: 0px auto;
    padding-top: 30px;
    margin: 0px ; 
}
.D6_AboveFooter_H1 {
    font-size: 24px;
    line-height: 40px;
    color: var(--greenColor);    font-weight: lighter;
    padding-left: 0px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--ycolor);
}
.D6_AboveFooter_P {
    font-size: 18px;
    padding-top: 35px;
    font-weight: 100;
    line-height: 25px;
}
#D6_AboveFooter_Item-2 {
    max-width: 150px;
    height: 260px;  
    margin: 0px auto;
}
#D6_AboveFooter_Item-3 {
    min-width: 240px;
    max-width: 90%;
    height: 260px; 
    margin: 0px auto; 
    margin: 0px ; 
    text-align: left !important;
}
#D6_AboveFooter_Item-5 {
    min-width: 160px;
    max-width: 95%;
    height: auto;
    /* height: 260px; */
    margin: 0px auto;
    margin: 0px;
    margin-bottom: 25px;
    text-align: left !important;

}
.D6_AF_Form_Div {
    max-width: 90%;
    /* height: 350px; */
    height: auto;
    
 /*   margin: 0px auto;
    margin: 0px ;  
    display: flex;
    justify-content: flex-end; */
}
.D6_AF_Form_Div_H {
    padding-left: 0px;
    color: white;
    font-weight: lighter;
 /*   padding-bottom: 20px; */
    font-size: 24px;
    line-height: 40px;
    width: 50%;
    /* margin-bottom: 20px; */
    border-bottom: 2px solid var(--ycolor);
}
.D6_AboveFooter_Item ul{
    list-style: none;
}
.D6_AboveFooter_Item li a{
    padding-right: 6px;
    text-decoration: none;
    color: white;
    font-size: 17px;
    padding-bottom: 10px;
    line-height: 48px;
}
.D6_AboveFooter_H2{
    padding-left: 185px;
    padding-left: 0px;
    color: white;
    font-weight: lighter;
    padding-bottom: 20px;
    font-size: 24px;
}
.D6_AboveFooter_List2 li a {
    padding-left: 185px;
    padding-left: 0px;
}
.D6_AboveFooter_H3{
    padding-left: 50px;
    padding-left: 0px;
    color: var(--greenColor);    font-weight: lighter;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 40px;
    width: 70%;
    border-bottom: 2px solid var(--ycolor);
}
.D6_AboveFooter_List3{
    padding-left: 50px;
    padding-left: 0px;
}

#D6_AboveFooter_Item-1 {
    overflow: hidden;
}

.D6_AboveFooter_Icons {
    text-decoration: none;
    margin-right: 20px;
    margin-top: 20px;
    margin-left: 10px;
    color: white;
}

.D6_AF_List li a{
    text-decoration: none;
    color: white;
    font-size: 19px;
    padding-bottom: 10px;
    line-height: 48px;
 /*   margin-left: 10px;    */
}

.D6_AboveFooter_Icon1 {
    color: white;
    margin: 10px 0px;
}

.D6_AboveFooter_Icon1 a{
    text-decoration: none;
}
.D6_AboveFooter_Icon_H {
    font-size: 16px;
    color: white;
    padding-left: 10px;
    margin-left: 15px;
}
.D6_AboveFooter_Icon_E {
    font-size: 16px;
    color: white;
    padding-left: 10px;
}

/*  --- Fa Symbols ---  */
.fa-envelope{
    color: white;
    font-size: 20px;
    /* padding-right: 15px; */
}
.fa-whatsapp{
    color: white;
    font-size: 25px;
    /* padding-right: 15px; */
}

.circle-icon {
  /*  background: rgb(53; 61; 66);   */
    background: transparent;
    border-radius: 50%;
    text-align: center; 
    line-height: 10px;
    padding: 15px 11px;
    vertical-align: middle;
    border: 2.6px solid white;
    transition: .3sec;
    color: white;
}
.circle-icon1 {
    /*  background: rgb(53; 61; 66);   */
      background: transparent;
      border-radius: 50%;
      text-align: center; 
      line-height: 10px;
      padding: 15px 13px;
      vertical-align: middle;
      border: 2.6px solid white;
      transition: .3sec;
      color: white;
  }

  .circle-icon1:hover {
    transform: scale(1.2);
    transition: .5s;
    /* background-color: var(--bcolor); */
    background-color: var(--greenColor);
    border-color: var(--ycolor);
}
.circle-icon:hover {
    transform: scale(1.2);
    transition: .5s;
    /* background-color: var(--bcolor); */
    background-color: var(--greenColor);
    border-color: var(--ycolor);
}
.circle-icon:hover .fa-facebook-f {
    color: var(--ycolor);
}
.centericons{
    text-align: center;
    color: white;
}
.fa-facebook-f{
    color: white;
    font-size: 20px;
    justify-content: center;
    text-align: center;
}
.circle-icon-f {
      padding: 15px 15px;
  }
.fa-instagram {
    color: white;
    font-size: 25px;
}
.fa-id-card{
    color: white;
    font-size: 20px;
}
.fa-qrcode{
    color: white;
    font-size: 20px;
}
.Dec6_AboveFooter_SymbolsList1{
    text-decoration: none;
    margin-left: 0px;
    margin-top: 20px;
    padding-left: 5px;
    padding-bottom: 10px;
}

.Dec6_AboveFooter_SymbolsList1 a{
    text-decoration: none;
    margin-right: 20px;
}
.D6_AboveFooter_Item5D1 {
    margin-right: 10px;
    /* padding-top: 10px; */
}
.D6_AboveFooter_HC {
    font-weight: 300;
    font-size: 20px;
    color: white;
}
.D6_AboveFooter_HC11 {
    padding-top: 10px;
    color:white;
}
.D6_AboveFooter_HC1 {
    font-weight: lighter;
    font-size: 16px;
    color:white;
    padding-top: 5px;
}
.openCard{
    font-size: 16px;
    color: black;
    display: none;
}




.Dialog_CButton.MuiPaper-root{
    margin:'0px';
}
.Dialog_CButton.MuiDialogContent-root{
    width: 300px;
          height:200px;
          text-align: center;
}
.Dialog_CButton.MuiPaper-rounded{
    border-radius: 22px;
}
.Dialog_CButton.MuiBackdrop-root{
    background: rgba(0,0,0,0.44);

}
.modal-title{
    margin-bottom: 0;
        font-weight: bold;
        text-align: left;
        font-size: 22px;
        line-height: 24px;
}

.icon-close-btn{
    width: 33px;
        height: 33px;
        position: absolute;
        top: -22px;
        left: 150px;
        padding: 0;
        background-color: transparent;
}
.icon-close-btn.MuiIconButton-label{
    display: block;

}
.icon-close-btn:hover{
    background-color: transparent;

}






@media (max-width: 1200px) {
    #D6_AboveFooter_Item-3 {
        margin-left: 10px;
    }
    .openCard{
        font-size: 16px;
        color: black;
        display: block;
    }
}

@media (max-width: 958px) {
    
    #D6_AboveFooter_Item-3 {
        margin-top: 30px ; 
        height: auto;
        margin-left: 0;
    }
    #D6_AboveFooter_Item-5 {
        margin-top: 30px ;
    }
    .D6_AF_Form_Div {
        height: auto;
        margin-top:0px;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .openCard{
        font-size: 16px;
        color: black;
        display: block;

    }
    
}

@media (max-width: 768px) {
    #D6_AboveFooter_Item-5 {
        margin-top: 20px ;
        margin-left: 10px;
        height: 280px;
    }
    #D6_AboveFooter_Item-3 {
        margin-left: 10px;
        height:auto;
        margin-top: 20px ;
        margin-right:10px;
        max-width: auto;
    }
    .D6_AF_Form_Div {
        margin-left: 10px;
    }
    .openCard{
        font-size: 16px;
        color: black;
        display: block;

    }
}

@media (max-width: 600px) {
    

    .D6_AF_Form_Div_H {
        padding-top: 20px;
        
    }
    .D6_AF_Form_Div {
        margin-left: 10px;
    }
    .openCard{
        font-size: 16px;
        color: black;
        display: block;

    }
}
