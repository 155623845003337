.main_ppr{
    height: 80px;
    display: grid;
    align-content: center;
    background: var(--blu3) !important;
    /* width: 80%; */
    margin:  2vh 0px;
    z-index: 1 !important;
    position: relative;
    max-width: 100%;
}
/* .card_products :hover{
scale: 1.05;
transition: .5s;
} */
/* .css-1ri6ub7-MuiPaper-root-MuiCard-root :hover{
    scale: 1.05;
    transition: .5s;
} */
