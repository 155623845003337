/* @import "../../MainCSS.css"; */


.MuiDrawer-paper{
    background-color: var(--blu3) !important;
}
.MuiAccordion-root{
    background-color: transparent !important;
box-shadow: none !important;
}
.typo{
    font-weight: 500 !important;
    font-size: 13px !important;
    color:white !important;
    /* border-bottom: white !important;
    border-bottom-width: 8px !important; */
    padding-left: 10px;
}
.typo1{
    font-weight: 500 !important;
    color:white !important;

    font-size: 13px !important;
    color:white !important;
    /* border-bottom: white !important;
    border-bottom-width: 8px !important; */
    padding-left: 10px;
    padding-bottom: 10px !important;
}
.typo1{
    padding-left: 0px !important;
    color:white !important;

    padding-bottom: 5px;
    font-weight: 500 !important;
    font-size: 13px !important;
    cursor: pointer;
}
.typo3{
    padding: 0px !important;
    color:white !important;

    color:white;
    font-weight: 500 !important;
    font-size: 13px !important;
    cursor: pointer;
}
.typo4{
    padding-left: 20px !important;
    color: white;
    font-weight: 500 !important;
    font-size: 13px !important;
    cursor: pointer;
    padding-bottom: 20px;
}
.box_W{
    width: 75vw !important;
}
@media (max-width:500px) {
    .box_W{
        width: 75vw !important;
    } 
}