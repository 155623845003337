
.head_about_new{
    padding-top: 5vh;
    /* padding-bottom: 2vh; */
    font-size: 28px !important;
    color: var(--blu3);
    text-align: center;
    text-transform: uppercase;
}
.react-multi-carousel-track{
    margin-bottom: 30px;
}
.react-multi-carousel-dot--active button {
    background: var(--blu3);
}
.react-multi-carousel-dot button{
    display: inline-block;
    width: 41px;
    height: 8px;
    border-radius: 10px;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    /* transition: background .5s; */
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
}