/* @import "../MainCSS.css"; */

.Project_Purpose_Block {
    width: 100%;
    padding: 10px 0px;
    min-height: 530px;
    background: url("../images/about1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: right 67%; */
    text-align: left;
    z-index: -1;
    /* margin-top: 110px; */
    font-family: var(--fontfamily);
}
.Project_Purpose_Block1 {
    margin-top: 10px;
    width: 100%;
    text-align: left;

    padding: 10px 0px;
    min-height: 530px;
    background: url("../images/believe.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 67%;
    z-index: -1;
    /* margin-top: 110px; */
    font-family: var(--fontfamily);
}

.Project_Purpose_Block2 {
    margin-top: 10px;
    width: 100%;
    text-align: left;

    padding: 10px 0px;
    min-height: 530px;
    background: url("../images/about3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 67%;
    z-index: -1;
    /* margin-top: 110px; */
    font-family: var(--fontfamily);
}
.Project_Purpose_Block_Cont {
 /*   position: absolute;
    top: 120px;
    left: 50px;  */
    display: inline-block;
    margin-top: 190px;
    margin-left: 30px;
    max-width: 400px;
    border-top: 5px solid var(--blu3);
    min-height: 170px;
    background: linear-gradient(rgb(255,255,255));
    background-color:white;
    opacity: 0.7;
    z-index: 2;
    padding-left: 20px;
    text-align: left;

}
.Project_Purpose_Block_Cont_MH {
    font-size: 1.55rem;
    color: black;
    padding-top: 13px;
}
.Project_Purpose_Block_Cont_P {
    font-size: 1.2rem;
    color: black;
    padding-top: 5px;
    padding-right: 10px;
}

/* big-landscape tablets, low resolution laptop and desktop */
@media (max-width: 1200px) {
    /* .Project_Purpose_Block {
        margin-top: 86.4px;
    } */
}

/* tablet,landscape, low resolution laptop and desktop */
@media (max-width: 990px) {
}

/* portrait tablet, e-readers kindle */
@media (max-width: 800px) {
    .Project_Purpose_Block_Cont {
           width: 320px;
           /* height: 145px; */
       }
       .Project_Purpose_Block_Cont_MH {
           font-size: 1.2rem;
           color: black;
           padding-top: 13px;
       }
       .Project_Purpose_Block_Cont_P {
           font-size: 0.95rem;
       }
}

/* smartphones, landscape i-phone */
@media (max-width: 600px) {
    .Project_Purpose_Block_Cont {
        width: 270px;
        /* height: 160px; */
        margin-top: 200px;
        padding-left:10px;
        margin-left: 10px;
    }
    .Project_Purpose_Block_Cont_MH {
        font-size: 1.2rem;
        color: black;
        padding-top: 13px;
    }
    .Project_Purpose_Block_Cont_P {
        font-size: 0.92rem;
    }
}

/* portrait smartphones, portrait i-phone */
@media (max-width: 500px) {
    .Project_Purpose_Block_Cont {
        width: 275px;
        min-height: 200px;
        margin-top: 200px;
        padding-left:5px;
        margin-left: 5px;
    }
    .Project_Purpose_Block_Cont_MH {
        font-size: 1rem;
        color: black;
        padding-top: 13px;
    }
    .Project_Purpose_Block_Cont_P {
        font-size: 0.94rem;
    }
}

