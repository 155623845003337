.bg_services{
    background-image:linear-gradient(rgba(34, 55, 115, .3),rgba(34, 55, 115, 1)
    ), url('../../images/ser.jpg');
    height: 100vh;
    background-size: cover;
    /* background-size: 100% 100%; */
    text-align: -webkit-center;
}

.crousel-content1{
    position: absolute;
        top: 30%;
        left: 60px;
        z-index: 2;
        /* transform: translateY(-50%); */
        z-index: 9;
        max-width: 80%;
        text-align: left;
        filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
    }
    
    
    .crousel-content1 h2{
    font-style: normal;
    color: #ffffff;
        font-weight: bold;
        font-size: 64px;
        line-height: 64px;
        letter-spacing: 1.1px;
        margin-bottom: 14px;
        position: relative;
    }
    
    
    .crousel-content1 p{
        color: #ffffff;
    
    /* font-family: Roboto; */
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 48px;
        letter-spacing: 0.1px;
        position: relative;
    }

  

    @media (max-width: 600px) {
    
        .crousel-content1 {
           position: absolute;
           top: 25%;
           left: 10px;
           z-index: 2;
           /* transform: translateY(-50%); */
           z-index: 9;
           max-width: 700px;
           text-align: left;
           filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
       }
       
        .crousel-content1 h2 {
           font-size: 30px
       }
       
     
        .crousel-content1 p {
           font-size: 18px;
       }
   }