.below_icon{
    width: 25%;
    padding-top: 20px;
    padding-bottom: 20px;
}





.below_icon_text{
  color:var(--blu1) ;
  animation: .3s ease;
}
.container2{
    
    padding-bottom: 50px;
    text-align: -webkit-center;
    margin-top: 30px;
    max-width: 100% !important;
    background: var(--blu1);
}
.cont_head{
    font-size: 34px !important;
    font-weight: 600 !important;
    color: white;
    padding-bottom: 10px;
}
.cont_text{
    font-size: 18px !important;
    text-align: justify;
    color: white;
    padding-bottom: 30px;
}
.below_icon1{

}
.head_sub{
    color: white;
    font-size: 18px !important;
    font-weight: 700 !important;
    padding-top: 10px;
    padding-bottom: 10px;
}
.text_sub{
    color: white;
    padding: 10px;
}

.topA_SubImg{
    width: 100%;
    /* background:url(../../../images/aboutSub.jpg ) ; */
    background-repeat: no-repeat;
    height: 750px;

}

.contA_sub{
    position: absolute;
    /* top: 62%; */
    padding-top: 31px;
    width: 100%;
    text-align: -webkit-right;
}
.bg_head2{
    width: 54%;
    text-align: justify;
    padding: 20px;

}
.bg_head2 h2{
    color: var(--blu3);
    
}
.bg_head2 h1{
    font-size: 23px;
    padding-bottom: 20px;
}
.bg_head2 p{
    font-size: 17px;
    line-height: 1.7;
    text-align: justify;
}
.chip_about{
    background: var(--blu3) !important;
    color: white !important;
    font-weight: 600 !important;
    padding: 10px !important;
    /* margin-left: 20px !important; */
}


.bg_belowTable{
    background: linear-gradient(rgba(0, 0, 0,0.6),rgba(0, 0, 0,0.7)),url('../../images/arch.jpg');
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 400px;
    margin-bottom: 40px;
    margin-top: 20px;

}
.head1{
    color: white;
    font-size: 22px !important;
    padding-top: 25px;
    padding-bottom: 10px;
    text-align: center;
}

.head2{
    font-size: 40px !important;
    color: white;
    text-align: center;
    font-weight: 600 !important;
    padding-bottom: 10px;
}
.head3{
    font-size: 28px;
    color: white;
    text-align: center;
}
.chip_below{
    padding: 10px !important;
    text-align: center;
    color: white !important;
    background-color: var(--blu3) !important;
    font-weight: 700;
    font-size: 14px !important;
    cursor: pointer !important;
}







