.contact_bg{
    background: linear-gradient(45deg,rgba(0,0,0,0.4),transparent),url('../images/contact.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 600px;
}
.contact_head{
    text-align: center;
    font-size: 19px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--blu3);
}
.contact_subhead{
    font-size: 25px !important;
    color: var(--blu3);
    font-weight: 600 !important;
    text-align: center;
    padding-bottom: 20px;

}
.btn_contact{
    width: 95% !important;
    font-weight: 600 !important;
    background: var(--blu3) !important;

}
.iconC{
    font-size: 45px;
    color: var(--blu3);
    padding-bottom: 5px;

}
.iconC_head{
    font-size: 25px !important;
    color: black;
    font-weight: 600 !important;
    padding-bottom: 5px;
}
.iconC_text{
    font-size: 18px !important;
    color: black;
}
.ContactUs_Map {
    width: 100%;
    height: 390px;
}
@media (max-width: 600px) {
    .contact_bg{
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: center center;
height: 40vh;}
}