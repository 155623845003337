.ppr_careers{
    background: var(--blu3)  !important;
    color: white !important;
    height: 150px;
    margin-bottom: 5vh;
    max-width: 200px;
    cursor: pointer;
}


.text_pprC{
    text-align: center;
    font-size: 18px !important;
    font-weight: 600 !important;
    /* padding-top: 9vh; */
    display: grid;
    height: 100%;
    align-content: center;
}
.text_pprC1{
    color: var(--blu3);

    text-align: center;
    font-size: 18px !important;
    font-weight: 600 !important;
    padding-top: 9vh; 
    display: none;
}
.ppr_careers:hover{
    /* background: white !important; */
    color: white !important;
}
/* .ppr_careers:hover .text_pprC{
    display: none;
}
.ppr_careers:hover .text_pprC1{
    display: block;
} */
.grid_BH{
    border-radius: 8px;
    padding: 2vh;
    /* background: #eee; */
    /* margin: 2vh; */

}
.ppr_BH{
    background: rgb(238, 238, 238) !important;
    box-shadow: none !important;
    padding: 2vh 10px !important;
}

@media (max-width:500px) {
    .ppr_careers {
      
        margin: 10px !important;
    }
    
}


/* 🚀 : 
🔧 : 
🌐 : 
🔗 : 
🌟 : 
📈: 
🌍 :  */
