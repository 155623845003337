.product_name{
    filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
    font-style: normal;
    color: #ffffff;
    font-weight: bold;
    font-size: 60px !important;
    line-height: 64px !important;
    letter-spacing: 1.1px !important;
    margin-bottom: 14px;
    position: relative;
    /* color: white; */
    /* height: 100%; */
    display: grid;
    align-items: center;
    text-transform: uppercase;
    padding-top: 200px;
}
.product_title{
    font-size: 35px !important;
    font-weight: 600 !important;
    color: var(--blu3) !important;
    text-align: start;
    text-transform: uppercase;
}
.product_details{
    padding: 2vh 0px;
    text-align: justify;
}
@media (max-width: 600px) {
    

    .product_name{
       
        font-size:23px !important;
     
    }
}
