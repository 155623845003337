.bg_topS{
    background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.4)), url('../../images/new_home.jpg');
    height: 100vh;
    background-size:cover;
    text-align: -webkit-center;
    background-position: center;
}




.max-width{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    max-width: unset;
}

.crousel-content{
position: absolute;
    top: 30%;
    left: 60px;
    z-index: 2;
    /* transform: translateY(-50%); */
    z-index: 9;
    max-width: 800px;
    text-align: left;
    filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
}


.crousel-content h2{
font-style: normal;
color: #ffffff;
    font-weight: bold;
    font-size: 55px;
    line-height: 64px;
    letter-spacing: 1.1px;
    margin-bottom: 14px;
    position: relative;
}


.crousel-content p{
    color: #ffffff;

/* font-family: Roboto; */
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 0.1px;
    position: relative;
}
.crousel-content p span{
    color: #ffffff;

   
        font-weight: 600;
       
    }
    .crousel-content p .sub-lebel{

        color: #ffffff;

        font-weight: 300;
    font-size: 22px;
    line-height: 29px;
    margin-top: 30px;
       
    }
    .bg_topCarrer{
        /* background-image:linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('../../../images/careers.jpg'); */
        height: 100vh;
        background-size: 100% 100%;
        text-align: -webkit-center;
    }
    
 .bg_headS{
    
    padding-top: 50vh;
    text-align: center;
    font-size: 35px !important;
    color: white;
    font-style: italic;
    width: 95%;
 }   
 .head_about{
    padding-top: 5vh;
    padding-bottom: 2vh;
    font-size: 24px !important;
    color: var(--blu3);
    text-align: center;

 }
 .text_about{
    padding-bottom: 5vh;
    text-align: justify;
    font-weight: lighter !important;
}
.text_ourM{
    padding-bottom: 5vh !important;
    font-weight: lighter !important;
    margin: 10px !important;
}
.ul_mission{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.ul_mission li{
    width: 18%;
    float: left;
    border: 1px solid var(--blu3);
    border-radius: 10px;
    text-align: center;
    margin-left: 1.2%;
    display: inline-block;
}
.block_mission{
    padding: 25px 15px;

}
.img_blockM img{
    width: 50px;
    margin-bottom: 10px;
}
@media (max-width: 600px) {
    .bg_topS {
        background-size: cover;
    }
     .crousel-content {
        position: absolute;
        top: 22%;
        left: 10px;
        z-index: 2;
        /* transform: translateY(-50%); */
        z-index: 9;
        max-width: 800px;
        text-align: left;
        filter: drop-shadow(0px 2px 7px rgba(5, 17, 33, 0.79));
    }
    
     .crousel-content h2 {
        font-size:27px
    }
    
     .crousel-content p {
font-size: 23px;
    }
     .crousel-content p {
        font-size: 20px;
    }
}